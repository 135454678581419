<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li
        v-if="agency"
        class="nav-item"
      >
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span class="brand-logo">
            <b-img
              :src="appLogoImage"
              alt="logo"
            />
          </span>
          <!--          <h2 class="brand-text mb-0">
            {{ appName }}
          </h2>-->
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg,
  },
  computed: {
    agency() {
      return this.$store.state.app.agency
    },
    appLogoImage() {
      return this.$store.state.app.agency?.logo.file_url
    },
  },
}
</script>

<style>

</style>
